import { Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";

import { AttachmentSlider } from "./AttachmentSlider";
import styles from "./CondensedReportView.module.scss";
import { getReportTimeDisplay, getReportTitle, isReportClosed } from "./utils";
import ExpandedMediaDisplay from "../../../common/ExpandedMediaDisplay";
import { GetSurveyResponse } from "../../../common/api-client/camera-api";
import {
  GetSubmissionResponsePortal,
  SubmissionAttachment,
  SubmissionPortal,
  SubmissionVersionTime,
} from "../../../common/api-client/or-api";
import { IPoliceServiceConfiguration } from "../../../common/serviceConfigurations";
import { statusTypes } from "../../../types/typesReportV2";
import { PrintPDF } from "../PDFGenerator";
import { modalDetails } from "../constants";
import { OnlineReportingContext } from "../context";
import {
  createCondensedModel,
  imageUrlToBase64,
  ReportAttachment,
} from "../utils";

/**
 *
 * @param supplementaryPDFStructure - this is basically an array of the pdfs of all the supplementary reports needed for printing
 * @returns
 */
const RenderCondensedReport = ({
  reportMetadata,
  organizationConfig,
  supplementaryPDFStructure,
  childrenSupplementaryReportSurveyJS,
  supplementaryAttachments,
  setSelectedIndex,
  selectedIndex,
  setIsSlideShowActive,
  isSlideShowActive,
}: {
  reportMetadata: GetSubmissionResponsePortal | SubmissionPortal;
  organizationConfig: IPoliceServiceConfiguration;
  supplementaryPDFStructure: { [key: string]: any }[];
  childrenSupplementaryReportSurveyJS: any[];
  supplementaryAttachments: Array<Array<SubmissionAttachment>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  selectedIndex: number;
  setIsSlideShowActive: Dispatch<SetStateAction<boolean>>;
  isSlideShowActive: boolean;
}) => {
  const navigate = useNavigate();
  const isClosed = isReportClosed(reportMetadata);
  const appName = window.location.pathname.split("/")[1];
  const { id, orgId } = useParams();
  const { getSubmissionForID, getSurveyForID } = useContext(
    OnlineReportingContext,
  );
  const [loading, setLoading] = useState(true);
  const [surveyResult, setSurveyResult] = useState<GetSurveyResponse | null>(
    null,
  );
  const [reportSurveyJS, setReportSurveyJS] = useState<{ [key: string]: any }>(
    {},
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [reportLatestVersion, setReportLatestVersion] = useState(
    reportMetadata?.latest_version?.toString() ?? "",
  );
  const [surveyModel, setSurveyModel] = useState<any>(new Model());
  const [basePDFStructure, setBasePDFStructure] = useState<{
    [key: string]: any;
  }>({});
  const [attachments, setAttachments] = useState<Array<SubmissionAttachment>>(
    [],
  );
  const [base64Data, setBase64Data] = useState<{ [key: string]: string }>({});
  const [newSurvey, setNewSurvey] = useState<Model>(new Model());
  const [reportAttachments, setReportAttachments] = useState<
    Array<ReportAttachment>
  >([]);
  const [baseSurveyStructure, setBaseSurveyStructure] = useState<any>({});
  const [reportVersionHistory, setReportVersionHistory] = useState<
    SubmissionVersionTime[]
  >([]);
  const [isSupplementaryReport, setIsSupplementaryReport] = useState(false);

  const getSurveyResult = async () => {
    const result = await getSurveyForID(reportMetadata.survey_id);
    if (result) {
      return result;
    } else {
      throw new Error("Survey result is null");
    }
  };

  const getMediaIndexUrl = (attachments: any[], index: number) => {
    console.log(attachments[index].incident_evidence_upload[0].content);
    return reportAttachments[index].incident_evidence_upload[0].content;
  };

  const extraPDFData = {
    incident_type: reportSurveyJS.description ?? "",
    status: statusTypes[reportMetadata.status]
      ? statusTypes[reportMetadata.status]
      : reportMetadata.status,
    tracking_number: reportMetadata.tracking_number ?? "",
    incident_number: reportMetadata.incident_number ?? "",
    time_created: reportMetadata.time_created,
    last_updated_by: reportMetadata.last_updated_by,
  };

  const editButton = () => {
    return (
      <Tooltip title={modalDetails.edit.description}>
        <Button
          variant="contained"
          color="info"
          size="medium"
          onClick={() => setIsEditDialogOpen(true)}
          endIcon={<Edit />}
        >
          Edit
        </Button>
      </Tooltip>
    );
  };

  async function updateSurveyOne(submissionVersion: string) {
    setReportLatestVersion(submissionVersion);

    if (getSubmissionForID) {
      const result = await getSubmissionForID(id ?? "", submissionVersion);
      const submission = result?.submission;
      setReportSurveyJS(submission);

      const localSurvey = new Model(baseSurveyStructure);
      localSurvey.data = submission;
      setSurveyModel(localSurvey);
    }
  }

  useEffect(() => {
    const fetchSurveyResultAndUpdateStates = async () => {
      try {
        const result = await getSurveyResult();
        setSurveyResult(result);

        if (result) {
          setBasePDFStructure(result.survey_pdf ?? {});
          setBaseSurveyStructure(result.survey);

          setAttachments(reportMetadata.attachments ?? []);
          const localSurvey = new Model(result.survey);
          const submission = reportMetadata.submission;
          localSurvey.data = submission;
          setReportSurveyJS(submission);
          setSurveyModel(localSurvey);
          setReportVersionHistory(reportMetadata.version_times ?? []);
          setIsSupplementaryReport(reportMetadata?.is_supplemental ?? false);
        }
      } catch (error) {
        console.error("Failed to fetch survey result:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResultAndUpdateStates();
  }, [id, reportMetadata]);

  useEffect(() => {
    async function load() {
      const tempData: { [key: string]: string } = {};
      for (const attachment of attachments) {
        if (
          attachment &&
          attachment.presigned_get &&
          attachment.content_type.includes("image")
        ) {
          const base64 = await imageUrlToBase64(
            attachment.presigned_get.s3_presigned.uri,
          );
          tempData[attachment.filename] = base64;
        }
      }
      setBase64Data(tempData);
    }
    load();
  }, [attachments, id]);

  useEffect(() => {
    if (!basePDFStructure.pages || !reportSurveyJS || !attachments) return;

    async function getData() {
      const { newSurvey, reportAttachments } = await createCondensedModel(
        basePDFStructure,
        reportSurveyJS,
        attachments,
      );
      setNewSurvey(newSurvey);
      setReportAttachments(reportAttachments);
    }
    getData();
  }, [basePDFStructure, reportSurveyJS, attachments, id]);

  if (loading) {
    return (
      <div
        style={{
          margin: "60px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} className={styles.paperContainer}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3 style={{ display: "inline-block", marginRight: "16px" }}>
              Incident Report
            </h3>{" "}
            Version: {reportMetadata.latest_version} -{" "}
            {getReportTimeDisplay(
              reportMetadata.time_created,
              reportMetadata.time_updated,
            )}
          </div>

          {!isClosed && editButton()}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography id="demo-simple-select-label">Displaying</Typography>
              <Select
                id="demo-simple-select"
                value={reportLatestVersion}
                variant="outlined"
                onChange={(event) => updateSurveyOne(event.target.value)}
              >
                {reportVersionHistory
                  .sort((a, b) => b.version - a.version)
                  .map((version) => (
                    <MenuItem
                      key={`version-${version.version}`}
                      value={version.version}
                    >
                      {version.version === reportMetadata.latest_version ? (
                        <span>
                          <strong>Latest Version</strong> -{" "}
                          {getReportTimeDisplay(version.time_created)}
                        </span>
                      ) : (
                        <span>
                          Version {version.version} -{" "}
                          {getReportTimeDisplay(version.time_created)}
                        </span>
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <PrintPDF
        basePDFStructure={basePDFStructure}
        report={{ ...reportSurveyJS, ...extraPDFData }}
        attachments={attachments}
        base64Data={base64Data}
        serviceName={organizationConfig?.fullName ?? ""}
        serviceLogoURL={organizationConfig?.logoURL ?? ""}
        reportTitle={getReportTitle(
          isSupplementaryReport,
          baseSurveyStructure?.title,
        )}
        hideAdminInformation={false}
        supplementaryReportsObject={{
          supplementaryPDFStructure: supplementaryPDFStructure,
          supplementaryReport: childrenSupplementaryReportSurveyJS,
          supplementaryAttachments: supplementaryAttachments,
        }}
      />
      <div className="condensed-report-wrapper">
        <Survey model={newSurvey} />
      </div>
      {reportAttachments.length > 0 ? (
        <div>
          <Typography
            variant="h6"
            style={{
              marginTop: "20px",
            }}
          >
            Attachments ({reportAttachments.length})
          </Typography>
          <AttachmentSlider
            reportAttachments={reportAttachments}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
      ) : (
        <Typography
          variant="h6"
          style={{
            marginTop: "20px",
          }}
        >
          No Attachments Present
        </Typography>
      )}
      {selectedIndex >= 0 && (
        <ExpandedMediaDisplay
          isSlideShowActive={isSlideShowActive}
          setIsSlideShowActive={setIsSlideShowActive}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          slideshowLength={reportAttachments.length}
          mediaSrc={getMediaIndexUrl(reportAttachments, selectedIndex)}
          mediaType={
            reportAttachments[
              selectedIndex
            ]?.incident_evidence_upload[0].type.includes("image")
              ? "image"
              : "video"
          }
        />
      )}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <DialogTitle>Edit this report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Editing the report can be used to correct mistakes, to add and
            remove information. A copy of the existing report will be saved for
            later retrieval
          </DialogContentText>
          <DialogContentText>
            <br />
            <b>Do you want to proceed with editing this report?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <Button
            onClick={() => {
              setIsEditDialogOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              navigate(`/${appName}/${orgId}/report/${id}/edit`, {
                replace: true,
              });
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default RenderCondensedReport;
