import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APIProvider } from "@vis.gl/react-google-maps";
import axios from "axios";
import { useContext, useEffect } from "react";
import {
  Admin,
  combineDataProviders,
  CustomRoutes,
  Resource,
} from "react-admin";
import {
  BrowserRouter,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { slk } from "survey-core";

import "./App.css";
import { DevEnvironmentProvider } from "./DevContext";
import ProtectedPage from "./ProtectedPage";
import { DrawerProvider } from "./common/SlideoutDrawer/DrawerContext";
import "./common/common.css";
import UserPage from "./components/UserManagement/User/UserPage/UserPage";
import UsersList from "./components/UserManagement/User/UsersList";
import AddPhone from "./components/addphone/AddPhone";
import { AlertSingle } from "./components/alerts/AlertSingle";
import { AlertView } from "./components/alerts/AlertView";
import { AlertList } from "./components/alerts/AlertsList";
import { EParadeList } from "./components/alerts/EParadeList";
import AlertGroupManagement from "./components/alerts/GroupManagement/AlertGroupManagement";
import AlertGroupUsersList from "./components/alerts/GroupManagement/AlertGroupUsersList";
import { UpdateAlert } from "./components/alerts/add/UpdateAlert";
import AlertAnalytics from "./components/alerts/analytics/AlertAnalytics";
import { ALERT_STATUS } from "./components/alerts/constants";
import {
  AlertViewProvider,
  SingleAlertProvider,
} from "./components/alerts/context";
import {
  AddRegistryPage,
  MapRegistryPage,
  SettingsPage,
} from "./components/canvas-registry";
import { ChangePassword } from "./components/changepassword/ChangePassword";
import SetPassword from "./components/changepassword/SetPassword";
import { AppSelector } from "./components/dashboard";
import ReportV2Analytics from "./components/online-reporting/ReportV2Analytics";
import ReportV2List from "./components/online-reporting/ReportV2List";
import ReportV2Settings from "./components/online-reporting/ReportV2Settings";
import ViewReport from "./components/online-reporting/ViewReport";
import CitizenEngage from "./components/online-reporting/citizen-engage/CitizenEngage";
import OnlineReportingProvider from "./components/online-reporting/context";
import { CondensedReportProvider } from "./components/online-reporting/report-view/CondensedReportProvider";
import CondensedReportView from "./components/online-reporting/report-view/CondensedReportView";
import reports from "./components/reports";
import VerifyPhone from "./components/verifyphone/VerifyPhone";
import { AuthStateContext, AuthStateProvider } from "./context";
import VSDataProvider from "./dataProvider/VSDataProvider";
import usersDataProvider from "./dataProvider/usersDataProvider";
import {
  APP_GOOGLE_PLACES_KEY,
  APP_SERVER_URL,
  APP_SURVEYJS_KEY,
} from "./environment";
import { Home, Layout } from "./layout";
import { ServiceLogin } from "./layout/ServiceLogin";
import { lightTheme } from "./layout/themes";
import "./styles/_utils.scss";

export const APIUrl = APP_SERVER_URL;
const queryClient = new QueryClient();

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "rubireport":
      return VSDataProvider(APIUrl);
    case "users-admin/users":
      return usersDataProvider(APIUrl);
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

const ProtectedAppSelector = () => {
  return (
    <ProtectedPage>
      <AppSelector />
    </ProtectedPage>
  );
};

const MFARequired = () => {
  useEffect(() => {
    toast.error(
      "Multi-factor authentication must be configured to use this login flow. Please reset your password and configure multi-factor authentication.",
    );
  }, []);
  return <Home />;
};

const HashCatch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", "")); // or history.replace
    }
  }, []);

  return <></>;
};

const ProfileUpdateCatch = () => {
  const { currentUser } = useContext(AuthStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.profile_update_required) {
      toast.info("Please update your profile information before proceeding.");
      navigate(`/users/${currentUser.id}/profile/edit`); // or history.replace
    } else {
      console.log("No profile update required");
    }
  }, [currentUser]);

  return <></>;
};

const Rv2RedirectToOrgURL = ({ appPath }: { appPath: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { currentUser } = useContext(AuthStateContext);

  useEffect(() => {
    const newPath = pathname.replace(
      `/${appPath}`,
      `/${appPath}/${currentUser?.rubi_org_id}`,
    );
    navigate(newPath);
  }, []);
  return <></>;
};

const App = () => {
  const { PUBLISHED, DRAFT } = ALERT_STATUS;

  useEffect(() => {
    slk(APP_SURVEYJS_KEY);

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Ignore login and user auth status checks
        // This was disrupting the auth context from capturing the redirection flow
        if (
          !window.location.href.includes("login") &&
          !error.response?.request.url.includes("user/me") &&
          error.response?.status === 401
        ) {
          window.location.href = "/login";
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <DevEnvironmentProvider>
          <AuthStateProvider>
            <AlertViewProvider>
              <OnlineReportingProvider>
                <BrowserRouter>
                  <HashCatch />
                  <ProfileUpdateCatch />
                  <Admin
                    title=""
                    dataProvider={dataProvider}
                    dashboard={ProtectedAppSelector}
                    loginPage={Home}
                    layout={Layout}
                    disableTelemetry
                    theme={lightTheme}
                  >
                    <Resource name="rubireport" {...reports} />
                    {/* <Resource name="users-admin/users" {...users} /> */}
                    <CustomRoutes>
                      {/* RubiREPORT v2 */}
                      <Route
                        path="/rubireportv2/:orgId/reports"
                        element={
                          <ProtectedPage>
                            <ReportV2List cisoDisplay={false} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/report/:id"
                        element={
                          <ProtectedPage>
                            <ViewReport editing={false} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/report/:id/edit"
                        element={
                          <ProtectedPage>
                            <ViewReport editing={true} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/report/:id/condensed"
                        element={
                          <ProtectedPage>
                            <CondensedReportProvider>
                              <CondensedReportView cisoDisplay={false} />
                            </CondensedReportProvider>
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/analytics"
                        element={
                          <ProtectedPage>
                            <CondensedReportProvider>
                              <ReportV2Analytics cisoDisplay={false} />
                            </CondensedReportProvider>
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/settings"
                        element={
                          <ProtectedPage>
                            <ReportV2Settings />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/*"
                        element={
                          <ProtectedPage>
                            <Rv2RedirectToOrgURL appPath="rubireportv2" />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubireportv2/:orgId/citizen-engage"
                        element={
                          <ProtectedPage>
                            <CitizenEngage />
                          </ProtectedPage>
                        }
                      />
                      {/* RubiINTEL */}
                      <Route
                        path="/rubiintel/:orgId/reports"
                        element={
                          <ProtectedPage>
                            <DrawerProvider>
                              <ReportV2List cisoDisplay={true} />
                            </DrawerProvider>
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/:orgId/report/:id"
                        element={
                          <ProtectedPage>
                            <ViewReport editing={false} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/:orgId/report/:id/edit"
                        element={
                          <ProtectedPage>
                            <ViewReport editing={true} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/:orgId/report/:id/condensed"
                        element={
                          <ProtectedPage>
                            <CondensedReportView cisoDisplay={true} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/:orgId/analytics"
                        element={
                          <ProtectedPage>
                            <ReportV2Analytics cisoDisplay />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/:orgId/settings"
                        element={
                          <ProtectedPage>
                            <ReportV2Settings />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubiintel/*"
                        element={
                          <ProtectedPage>
                            <Rv2RedirectToOrgURL appPath="rubiintel" />
                          </ProtectedPage>
                        }
                      />
                      {/* RubiALERT */}
                      <Route
                        path="/rubialert/alerts"
                        element={
                          <ProtectedPage>
                            {/* This is needed for drawer view */}
                            <SingleAlertProvider>
                              <DrawerProvider>
                                <AlertList />
                              </DrawerProvider>
                            </SingleAlertProvider>
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubialert/alerts/:id/single"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <AlertSingle mode={PUBLISHED} />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/alerts/:id/single/edit"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <UpdateAlert mode="edit" />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/drafts"
                        element={
                          <ProtectedPage>
                            <DrawerProvider>
                              <AlertList isDraftsList />
                            </DrawerProvider>
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubialert/drafts/:id/single"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <AlertSingle mode={DRAFT} />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/drafts/:id/single/edit"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <UpdateAlert mode="edit" />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/add-alert"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <UpdateAlert mode="add" />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/upload-alert"
                        element={
                          <SingleAlertProvider>
                            <ProtectedPage>
                              <UpdateAlert mode="upload" />
                            </ProtectedPage>
                          </SingleAlertProvider>
                        }
                      />
                      <Route
                        path="/rubialert/analytics"
                        element={
                          <ProtectedPage>
                            <AlertAnalytics />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubialert/manage-groups"
                        element={
                          <ProtectedPage>
                            <AlertGroupManagement />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubialert/manage-groups/:groupId/members"
                        element={
                          <ProtectedPage>
                            <AlertGroupUsersList />
                          </ProtectedPage>
                        }
                      />
                      {/* RubiPARADE */}
                      <Route
                        path="/rubiparade/alerts"
                        element={
                          <ProtectedPage>
                            <EParadeList />
                          </ProtectedPage>
                        }
                      />
                      {/* RubiCANVAS */}
                      <Route
                        path="/rubicanvas/map"
                        element={
                          <ProtectedPage>
                            <MapRegistryPage />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubicanvas/add-camera"
                        element={
                          <ProtectedPage>
                            <AddRegistryPage />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/rubicanvas/settings"
                        element={
                          <ProtectedPage>
                            <SettingsPage />
                          </ProtectedPage>
                        }
                      />
                      {/* Users List */}
                      <Route
                        path={"organizations/:orgId/users"}
                        element={
                          <ProtectedPage>
                            <UsersList />
                          </ProtectedPage>
                        }
                      />
                      {/* View User */}
                      <Route
                        path={"organizations/:orgId/users/:userId"}
                        element={
                          <ProtectedPage>
                            <UserPage mode="view" profile={false} />
                          </ProtectedPage>
                        }
                      />
                      {/* Add User */}
                      <Route
                        path={"organizations/:orgId/users/new"}
                        element={
                          <ProtectedPage>
                            <UserPage mode="add" profile={false} />
                          </ProtectedPage>
                        }
                      />
                      {/* Update User */}
                      <Route
                        path={"organizations/:orgId/users/:userId/edit"}
                        element={
                          <ProtectedPage>
                            <UserPage mode="edit" profile={false} />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path={"users/:userId/profile"}
                        element={
                          <ProtectedPage>
                            <UserPage mode="view" profile />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path={"users/:userId/profile/edit"}
                        element={
                          <ProtectedPage>
                            <UserPage mode="edit" profile />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="/organizations/*"
                        element={
                          <ProtectedPage>
                            <Rv2RedirectToOrgURL appPath="organizations" />
                          </ProtectedPage>
                        }
                      />
                    </CustomRoutes>
                    <CustomRoutes noLayout>
                      {/* TODO pre-pend with rubialert */}
                      <Route
                        path={"/view-alert"}
                        element={
                          <SingleAlertProvider>
                            <AlertView />
                          </SingleAlertProvider>
                        }
                      />
                      <Route path="/setpassword" element={<SetPassword />} />
                      <Route
                        path="/changepassword"
                        element={<ChangePassword />}
                      />
                      <Route path="/addphone" element={<AddPhone />} />
                      <Route
                        path="/verifyphone"
                        element={<VerifyPhone verifyType="phone" />}
                      />
                      <Route
                        path="/confirmotp"
                        element={
                          <VerifyPhone verifyType="phone" previouslyConfirmed />
                        }
                      />
                      <Route
                        path="/verifyemail"
                        element={<VerifyPhone verifyType="email" />}
                      />
                      <Route
                        path="/resetpassword"
                        element={<ChangePassword oldPasswordRequired={false} />}
                      />
                      <Route path="/mfarequired" element={<MFARequired />} />
                      <Route
                        path="/:organizationSlug/login"
                        element={<ServiceLogin />}
                      />
                    </CustomRoutes>
                  </Admin>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
              </OnlineReportingProvider>
            </AlertViewProvider>
          </AuthStateProvider>
        </DevEnvironmentProvider>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-center"
        limit={1}
        autoClose={3000}
        hideProgressBar={false}
      />
    </LocalizationProvider>
  );
};

export default App;
